import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { axios } from '../../../../../App';
import _ from "lodash";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { Space, Button, Row, Col, Input, Tooltip, DatePicker, Table } from "antd";
import qs from "qs";
import { ReportDataTable } from '../../../components';
import { DownloadTableExcel } from 'react-export-table-to-excel';

const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss[Z]";

const SubContractorLedger = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 1000,
  });
  const [total, setTotal] = useState(0);
  const [searchValues, setSearchValues] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [chartData, setChartData] = useState([]);
  const reportRefs = useRef([]);
  const tableRef = useRef(null);


  const generateDateRange = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];

    while (startDate.isSameOrBefore(endDate)) {
      dates.push(startDate.format('YYYY-MM-DD'));
      startDate = startDate.add(1, 'days');
    }

    return dates;
  };

  const handleStartDateChange = (value) => {
    setSearchValues((prev) => {
      const updatedValues = { ...prev, start_date: value };
      if (updatedValues.start_date && updatedValues.end_date) {
        setDateRange(generateDateRange(updatedValues.start_date, updatedValues.end_date));
      }
      return updatedValues;
    });
  };

  const handleEndDateChange = (value) => {
    setSearchValues((prev) => {
      const updatedValues = { ...prev, end_date: value };
      if (updatedValues.start_date && updatedValues.end_date) {
        setDateRange(generateDateRange(updatedValues.start_date, updatedValues.end_date));
      }
      return updatedValues;
    });
  };

  const processData = (data) => {
    const projectCosts = {};

    data?.forEach((item) => {
      const projectName = item.attributes.project.data.attributes.name;
      const projectCost = item.attributes.grand_total_amount;

      if (projectCosts[projectName]) {
        projectCosts[projectName] += projectCost;
      } else {
        projectCosts[projectName] = projectCost;
      }
    });

    return Object.keys(projectCosts).map((key) => ({
      type: key,
      value: projectCosts[key],
    }));
  };


  const fetchData = async (params = null) => {
    try {
      setLoading(true);

      const queryObject = {
        populate: "*",
        sort: ["estimated_amount:desc"],
      };

      const subQueryObject = {
        populate: "*",
      };

      if (!_.isEmpty(params)) {
        queryObject["filters"] = params;
      }

      const response = await axios.get(
        `sub-contractor-quotations?${qs.stringify(queryObject)}`
      );

      const subContractorResponse = await axios.get(
        `sub-contractor-debit-notes?${qs.stringify(subQueryObject)}`
      );

      const debitResponse = await axios.get(
        `sub-contractor-debit-notes?${qs.stringify(subQueryObject)}`
      );

      const creditResponse = await axios.get(
        `sub-contractor-credit-notes?${qs.stringify(subQueryObject)}`
      );

      const extraWorkResponse = await axios.get(
        `sub-contractor-extra-works?${qs.stringify(subQueryObject)}`
      );

      console.log("debug", debitResponse, creditResponse, extraWorkResponse)

      if (response?.data) {
        setData(
          response?.data?.data?.map((item) => ({
            id: item?.id,
            sub_contractor_group: subContractorResponse?.data?.data?.find((sub) => sub.id === item.attributes.sub_contractor.data.id)?.attributes?.sub_contractor_group,
            debit_notes: debitResponse?.data?.data?.filter((deb) => deb?.attributes?.sub_contractor?.data.id === item.attributes?.sub_contractor?.data.id),
            credit_notes: creditResponse?.data?.data?.filter((cre) => cre?.attributes?.sub_contractor?.data.id === item.attributes?.sub_contractor?.data.id),
            extra_work: extraWorkResponse?.data?.data?.filter((ext) => ext?.attributes?.sub_contractor?.data.id === item.attributes?.sub_contractor?.data.id),
            ...item?.attributes,
          }))
        );

        setTotal(response?.data?.meta?.pagination?.total);

        setPagination({
          ...response?.data?.meta?.pagination,
          pageSize: 1000,
        });

        const chartData = processData(response?.data?.data);

        setChartData(chartData)
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const refreshData = () => {
    onSearch();
  };

  const onSearch = () => {
    fetchData({
      $or: [
        {
          heading: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          remarks: {
            $containsi: searchValues?.keyword?.toLowerCase(),
          },
        },
        {
          createdAt: {
            $gte: moment(searchValues?.start_date).format(DATE_TIME_FORMAT),
            $lte: moment(searchValues?.end_date).format(DATE_TIME_FORMAT),
          },
        },
      ],
    });
  };

  const onReset = () => {
    setSearchValues({});
    fetchData({
      status: "Approved",
    });
  };

  const getResultsMessage = () => {
    if (!_.isEmpty(searchValues)) {
      return total > 0 ? (
        <span>
          <strong>{total}</strong> matched results
        </span>
      ) : (
        <span>No matched results</span>
      );
    }

    return (
      <span>
        <strong>{total}</strong> total records
      </span>
    );
  };


  const columns = [
    {
      title: 'Scheduled Date',
      dataIndex: 'scheduled_date',
      key: 'scheduled_date',
    },
    {
      title: 'Work order',
      dataIndex: 'work_order',
      key: 'work_order',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
    },

    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
    },
    {
      title: 'Extra Work',
      dataIndex: 'extra_work',
      key: 'extra_work',
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
  ];

  const dataSource = data?.map(item => {
    const jdf = dateRange?.map(schedule => {
      const debitNote = item?.debit_notes?.find(d => moment(d.attributes.createdAt).format('YYYY-MM-DD') == schedule);
      const creditNote = item?.credit_notes?.find(c => moment(c.attributes.createdAt).format('YYYY-MM-DD') == schedule);
      const extraWork = item?.extra_work?.find(e => moment(e.attributes.createdAt).format('YYYY-MM-DD') == schedule);
      const workOrder = item?.delivery_date == schedule ? item.grand_total_amount : null;

      const debit = debitNote ? debitNote.attributes.grand_total : null;
      const credit = creditNote ? creditNote.attributes.grand_total : null;
      const extraWorkAmount = extraWork ? extraWork.attributes.grand_total : null;

      // Calculate the total amount
      const totalAmount = (workOrder + credit + extraWorkAmount) - debit;

      // Check if all the amounts are zero or falsy
      if (!debit && !credit && !extraWorkAmount && !workOrder) {
        return null; // Return null to filter out later
      }

      return {
        key: schedule,
        scheduled_date: schedule,
        work_order: workOrder ?? 0,
        debit: debit ?? '--',
        credit: credit ?? '--',
        extra_work: extraWorkAmount ?? '--',
        total_amount: totalAmount,
        project: item.project,
        sub_contractor: item.sub_contractor,
      };
    }).filter(entry => entry !== null); // Filter out null entries

    return jdf;
  });


  const SubTable = () => (
    <>
      {dataSource?.map((tab, index) => {

        if (!tab) return null;

        const totalCost = tab?.reduce((acc, item) => acc + item.total_amount, 0);
        const totalWorkOrder = tab?.reduce((acc, item) => acc + item.work_order, 0);

        // Add a total cost row
        const totalCostRow = {
          key: 'total',
          heading: '',
          updatedAt: '',
          project: null,
          sub_contractor: null,
          total_amount: `Total Cost - ${totalCost.toLocaleString()}`
        };

        return (
          <div key={index} ref={el => reportRefs.current[index] = el} style={{ marginBottom: '20px' }}>
            <div style={styles.details}>
              <p>Project Name: {tab?.[tab?.length - 1]?.project?.data?.attributes?.name}</p>
              <p>SubContractor Name: {tab?.[tab?.length - 1]?.sub_contractor?.data?.attributes?.name}</p>
              <p>Work order: {totalWorkOrder}</p>
            </div>
            <Table columns={columns} dataSource={[...tab, totalCostRow]} pagination={false} />
          </div>
        )
      })}

    </>
  );

  useEffect(() => {
    fetchData({
      status: "Approved",
    });
  }, []);

  console.log('work order', data, dataSource)

  return (
    <>
      <ReportDataTable
        // data={[...data, totalCostRow]}
        config={{
          pageTitle: 'Subcontract Ledger Report',
          slugs: {
            plural: 'Subcontract Ledger Report',
          }
        }}
        loading={loading}
        pagination={pagination}
        reportRefs={reportRefs}
        showExportButton={false}
        // actions={{
        //   onAdd: () => navigate(`/quick-stocks/${config?.slugs?.plural}/add`),
        //   onRefresh: refreshData,
        // }}
        Toolbar={
          <div className="toolbar">
            <Row gutter={16}>
              {/* <Col span={10}>
                <Input
                  value={searchValues?.keyword}
                  placeholder="Search for keyword..."
                  onChange={(e) =>
                    setSearchValues((prev) => ({
                      ...prev,
                      keyword: e.target.value,
                    }))
                  }
                />
              </Col> */}

              <Col span={7}>
                <DatePicker
                  onChange={handleStartDateChange}
                  value={searchValues?.start_date}
                  placeholder="Select start date"
                  style={styles.datePicker}
                />
              </Col>

              <Col span={7}>
                <DatePicker
                  onChange={handleEndDateChange}
                  value={searchValues?.end_date}
                  placeholder="Select end date"
                  style={styles.datePicker}
                />
              </Col>
            </Row>

            <div className="toolbar-buttons">
              <p className="toolbar-results">{getResultsMessage()}</p>

              <Space>
                <Button onClick={onReset}>Reset</Button>
                <Button type="primary" onClick={onSearch}>
                  Search
                </Button>
              </Space>
            </div>
          </div>
        }
        buttons={[
          <Tooltip placement="top" title="Refresh">
            <Button
              icon={<ReloadOutlined />}
              onClick={refreshData}
              loading={loading}
            />
          </Tooltip>,

          <DownloadTableExcel
            filename="subcontractor_ledger"
            sheet="ledger_sheet"
            currentTableRef={tableRef.current}
          >

            <Button icon={<DownloadOutlined />} >
            </Button>
          </DownloadTableExcel>
        ]}
        onBack={() => navigate(`/quick-stocks/reports`)}
      />

      <div ref={tableRef}>
        <SubTable />
      </div>


    </>
  );
};


const styles = {
  datePicker: {
    width: "100%",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    margin: '20px 0'
  }
};

export default SubContractorLedger;